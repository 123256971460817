import { PencilIcon } from "@heroicons/react/outline";
import { LockClosedIcon, MailIcon } from "@heroicons/react/solid";
import { useFlag } from "@unleash/proxy-client-react";
import { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  InvoiceAccountUI,
  Warehouse,
  getBadgeSettingsFromOrderType,
  getDefaultOrderName,
  shouldRenderOrderType,
} from "@web/common";
import {
  AgentInformation as AgentInformationInfo,
  Combobox,
  ContactInformation,
  DeliveryDate,
  SummaryBox,
} from "@web/common/components";
import {
  DeliveryAddress,
  DeliveryMethod,
  OrderComment,
} from "@web/common/pages/OrderInfo/components";
import { StorageLabelInput } from "@web/common/pages/OrderInfo/components/StorageLabelInput";
import { getBackwardsCompatibleTime } from "@web/common/utils/getBackwardsCompatibleTime";
import { isDateNotInPast } from "@web/common/validators";
import { InvoiceAccount } from "@web/models";
import { Badge, FormSection, Heading, Input, Paragraph } from "@web/ui";
import { convertTimeToISODateString, extractFromISODateString, useFuzzySearch } from "@web/utils";

import { useAppStateContext } from "src/contexts/AppStateContext";
import useBasket from "src/hooks/useBasket";
import { LocalConfigurationService } from "src/services/LocalConfigurationService";
import AppState from "src/state/models";
import {
  CreateLiteAgentInformation,
  LiteCreateRequisitionRequest,
  LiteOrderExtraItem,
  LiteRequesterInformation,
  LiteRequisitionCreationItem,
} from "src/typegens";

export type SubmitRequisitionFormData = Omit<
  LiteCreateRequisitionRequest,
  "vesselId" | "supplierId" | "catalogItems" | "extraItems" | "portId" | "orderType" | "rfqItems"
>;

interface Props {
  loading: boolean;
  submitForm: (requisitionSummary: SubmitRequisitionFormData) => void;
  validationErrorMessage?: string;
  warehouses?: Warehouse[];
  renderAttentionInfo?: ({ className }: { className: string }) => React.ReactElement | null;
  updateOrderNameInState?: (value: string) => void;
}

type FormInputs = {
  agentInformation?: CreateLiteAgentInformation;
  basketId?: string;
  catalogItems: Array<LiteRequisitionCreationItem>;
  extraItems: Array<LiteOrderExtraItem>;
  consolidated: boolean;
  customerOrderId?: string;
  deliveryDate: {
    time: string;
    date: string;
  };
  draftId?: string;
  invoiceAccount?: InvoiceAccount;
  orderNotes?: string;
  requesterInformation: LiteRequesterInformation;
  storageLabel?: string;
  warehouseId?: string;
  subject?: string;
};

type FormValues = {
  agentInformation?: CreateLiteAgentInformation;
  basketId?: string;
  consolidated: boolean;
  customerOrderId?: string;
  deliveryDate: string;
  draftId?: string;
  invoiceAccountId?: string;
  orderNotes?: string;
  requesterInformation: LiteRequesterInformation;
  storageLabel?: string;
  warehouseId?: string;
  subject?: string;
};

const RequisitionInfo: React.FC<Props> = ({
  submitForm,
  loading,
  validationErrorMessage,
  renderAttentionInfo,
  updateOrderNameInState,
}) => {
  /* Hooks */

  const { t } = useTranslation();
  const { grandTotal, lineItems, rfqItems, extraItems } = useBasket();
  const [appState] = useAppStateContext();

  /* Feature flags */

  const hasInvoiceAccountsFeature = useFlag("invoice-accounts");
  const hasConsolidatedOrdersFeature = useFlag("consolidated-orders");
  const hasSelectSupplierFeature = useFlag("select-supplier");

  /* State from App */

  const { deliveryDate, orderType, port, supplier, orderName, configuration } = appState;

  const hasFMSIntegration = !!configuration?.fleet.allow.fmsIntegration;
  const invoiceAccounts = hasInvoiceAccountsFeature
    ? (configuration?.vessel?.invoiceAccounts as InvoiceAccount[])
    : [];
  const vessel = (appState as Required<AppState>).configuration?.vessel;
  const warehouses = configuration?.warehouses ?? [];
  const isAutoApprovedRequisition = !!configuration?.isAutoApprovedRequisition;
  const orderTypes = hasSelectSupplierFeature
    ? configuration?.orderTypeConfigs
    : configuration?.orderTypes;
  const defaultAgentInformation = useMemo(
    () =>
      hasSelectSupplierFeature
        ? LocalConfigurationService.getSelectedSupplierConfig({
            orderTypeConfigs: configuration?.orderTypeConfigs,
            orderType,
            portId: port?.id,
            supplierId: supplier?.id,
          })?.agentInformation
        : port?.agentInformation,
    [
      configuration?.orderTypeConfigs,
      hasSelectSupplierFeature,
      orderType,
      port?.agentInformation,
      port?.id,
      supplier?.id,
    ]
  );

  /* Others */

  const { filteredCollection: filteredAccounts, handleSearchPatternChange } = useFuzzySearch({
    collection: invoiceAccounts || [],
    keys: ["registerOwner"],
  });

  const invoiceAccountsMessages = {
    placeholder: t("components.invoiceAccountSelector.placeholder"),
    noValueLabel: t("components.invoiceAccountSelector.noValueLabel"),
    noValueSubLabel: t("components.invoiceAccountSelector.noValueSubLabel"),
  };

  /* Form */
  const defaultValues = {
    requesterInformation: {
      email: configuration?.vessel.contactInformation?.email ?? "",
      name: configuration?.vessel.contactInformation?.name ?? "",
    },
    agentInformation: {
      firstName: defaultAgentInformation?.firstName ?? "",
      lastName: defaultAgentInformation?.lastName ?? "",
      email: defaultAgentInformation?.email ?? "",
      phoneNumber: defaultAgentInformation?.phoneNumber ?? "",
      company: defaultAgentInformation?.company ?? "",
    },
    customerOrderId: "",
    consolidated: false,
    orderNotes: "",
    storageLabel: "",
    deliveryDate: {
      time: getBackwardsCompatibleTime(extractFromISODateString(deliveryDate, "justTime")),
      date: extractFromISODateString(deliveryDate, "yearFirst"),
    },
    invoiceAccount: undefined,
    warehouseId: "",
    subject: orderName,
  };

  const form = useForm<FormInputs>({
    defaultValues: defaultValues,
    mode: "onSubmit",
    shouldUnregister: true,
  });

  const { control, formState, handleSubmit, register, setValue, watch, getValues } = form;

  const { errors } = formState;

  /* Conditions */

  const shouldDisplayBillingInformation = invoiceAccounts && invoiceAccounts.length > 0;
  const shouldDisplayDeliveryAddress = watch("consolidated");
  const shouldDisplayDeliveryMethod =
    hasConsolidatedOrdersFeature && warehouses && warehouses.length > 0;

  const submit = async () => {
    const { invoiceAccount, ...values } = form.getValues();
    const submittedDeliveryDate = convertTimeToISODateString(
      values.deliveryDate?.time,
      values.deliveryDate?.date
    );
    const data: FormValues = {
      ...values,
      deliveryDate: submittedDeliveryDate,
      warehouseId: values.consolidated ? values.warehouseId : undefined,
      invoiceAccountId: shouldDisplayBillingInformation ? invoiceAccount?.id : undefined,
      subject: values.subject || getDefaultOrderName(port, submittedDeliveryDate),
    };

    const filtered = Object.fromEntries(
      Object.entries(data).filter(([, value]) => value != undefined)
    );
    submitForm(filtered as FormValues);
  };

  const Divisor = () => <hr className="my-2" />;

  const requesterName = watch("requesterInformation.name");
  const requesterEmail = watch("requesterInformation.email");

  const onOrderNameChange = useCallback(
    (value: string) => {
      updateOrderNameInState?.(value);
      setValue("subject", value, {
        shouldDirty: true,
      });
    },
    [setValue, updateOrderNameInState]
  );

  const onDateTimeChange = useCallback(
    (time: string, date: string) => {
      const oldDate = getValues().deliveryDate.date;

      setValue("deliveryDate.date", date, {
        shouldDirty: true,
        shouldValidate: (() => date != "")(),
      });
      setValue("deliveryDate.time", time, {
        shouldDirty: true,
        shouldValidate: (() => date != "")(),
      });

      const preChangeDefaultOrderName = getDefaultOrderName(port, oldDate);
      if (orderName === preChangeDefaultOrderName) {
        onOrderNameChange(getDefaultOrderName(port, date));
      }
    },
    [getValues, onOrderNameChange, orderName, port, setValue]
  );

  return (
    <>
      <div className="flex-grow">
        <div className="mb-6">
          <Heading size="300">
            {isAutoApprovedRequisition
              ? t("pages.autoApprovedRequisitionSummary.title")
              : t("pages.requisitionSummary.title")}{" "}
            {orderType && shouldRenderOrderType(orderTypes, orderType) && (
              <Badge {...getBadgeSettingsFromOrderType({ orderType, orderTypes })} size="s" />
            )}
          </Heading>
          <Paragraph size="200" color="text-textIcon-blackSecondary">
            {isAutoApprovedRequisition
              ? t("pages.autoApprovedRequisitionSummary.detail")
              : t("pages.requisitionSummary.detail")}
          </Paragraph>
        </div>
        <form className="flex flex-col flex-grow gap-6" name="contact_info">
          {/* TODO: #11899 - Lift the order name field above the contact information heading*/}
          <ContactInformation
            emailField={
              <Controller
                name="requesterInformation.email"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    {...register("requesterInformation.email")}
                    prefixIcon={<MailIcon className="text-textIcon-blackSecondary h-4 w-4" />}
                    type="email"
                    id="Email"
                    label={t("components.contactInfo.emailLabel")}
                    placeholder={t("components.contactInfo.emailPlaceholder")}
                    maxLength={320}
                    value={value}
                    onInputChange={onChange}
                    errorMessage={errors.requesterInformation?.email?.message}
                    testId="requesterEmail"
                  />
                )}
              />
            }
            fullNameField={
              <Controller
                name="requesterInformation.name"
                control={control}
                render={({ field: { value, onChange }, formState: { errors } }) => (
                  <Input
                    {...register("requesterInformation.name")}
                    label={t("components.contactInfo.fullNamePlaceholder")}
                    prefixIcon={<LockClosedIcon className="text-textIcon-blackSecondary h-4 w-4" />}
                    placeholder={t("components.contactInfo.internalRequesterLabel")}
                    maxLength={100}
                    value={value}
                    onInputChange={onChange}
                    errorMessage={errors.requesterInformation?.name?.message}
                    testId="requesterName"
                  />
                )}
              />
            }
            orderNameField={
              <Controller
                name="subject"
                control={control}
                render={({ field: { value, onChange, ...rest } }) => (
                  <Input
                    {...rest}
                    prefixIcon={<PencilIcon className="text-textIcon-blackSecondary h-4 w-4" />}
                    type="text"
                    label="Order name"
                    placeholder="Enter order name"
                    maxLength={128}
                    value={value}
                    onInputChange={onOrderNameChange}
                    testId="orderNameField"
                    errorMessage={errors.subject?.message}
                  />
                )}
              />
            }
          />
          <Divisor />
          <FormSection title={"Delivery date"} subtitle={t("Enter the delivery date")}>
            <DeliveryDate
              initialTime={defaultValues?.deliveryDate.time}
              initialDate={defaultValues?.deliveryDate.date}
              errors={errors}
              datePickerAdditionalProps={register("deliveryDate.date", {
                required: {
                  value: true,
                  message: t("components.contactInfo.dateEmpty"),
                },
                validate: {
                  notInPast: (value) =>
                    isDateNotInPast(value, t("components.contactInfo.dateNotInTheFuture")),
                },
              })}
              timePickerAdditionalProps={register("deliveryDate.time", {
                required: {
                  value: true,
                  message: t("components.contactInfo.timeEmpty"),
                },
              })}
              onChange={onDateTimeChange}
            />
          </FormSection>
          <Divisor />
          {shouldDisplayDeliveryMethod && (
            <Controller
              name="consolidated"
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  <DeliveryMethod
                    isConsolidatedOrder={value}
                    onChangeIsConsolidatedOrder={(value) => {
                      onChange(value);
                    }}
                  />
                  <Divisor />
                </>
              )}
            />
          )}
          {shouldDisplayDeliveryAddress && (
            <Controller
              name="warehouseId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("components.contactInfo.deliveryAddress.validation.required"),
                },
              }}
              render={({ field: { ref, onChange, value, ...rest }, formState: { errors } }) => (
                <>
                  <DeliveryAddress
                    preselectedWarehouse={
                      warehouses.length === 1
                        ? warehouses[0]
                        : warehouses.find((warehouse) => value === warehouse.id)
                    }
                    onSelectWarehouse={(warehouse) => onChange(warehouse?.id)}
                    warehouses={warehouses}
                    errors={errors.warehouseId?.message}
                    {...rest}
                  />
                  <Divisor />
                </>
              )}
            />
          )}
          {shouldDisplayBillingInformation && (
            <Controller
              name="invoiceAccount"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <FormSection
                    subtitle={t("components.contactInfo.billingInformation.subtitle")}
                    title={t("components.contactInfo.billingInformation.title")}
                  >
                    <Combobox
                      filteredOptions={filteredAccounts}
                      messages={{
                        ...invoiceAccountsMessages,
                        validationError: errors.invoiceAccount?.message,
                      }}
                      onSearchPatternChange={handleSearchPatternChange}
                      onSelectOption={onChange}
                      preselectedOption={invoiceAccounts.find(
                        (invoice) => invoice.id === value?.id
                      )}
                      optionComponent={InvoiceAccountUI}
                      options={invoiceAccounts}
                    />
                  </FormSection>
                  <Divisor />
                </>
              )}
            />
          )}
          <Controller
            name="agentInformation"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <AgentInformationInfo
                  defaultAgentInformation={value}
                  errors={errors}
                  onChange={onChange}
                  register={register}
                  required={{
                    firstName: false,
                    lastName: false,
                    company: !!isAutoApprovedRequisition,
                    email: !!isAutoApprovedRequisition,
                    phoneNumber: !!isAutoApprovedRequisition,
                  }}
                />
                <Divisor />
              </>
            )}
          />
          <Controller
            name="storageLabel"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <StorageLabelInput onChange={onChange} defaultValue={value} />
                <Divisor />
              </>
            )}
          />
          <Controller
            name="orderNotes"
            control={control}
            render={({ field: { value, onChange } }) => (
              <OrderComment comment={value} onChange={onChange} />
            )}
          />
        </form>
      </div>
      <div className="ml-8 w-1/3">
        <Controller
          name="customerOrderId"
          control={control}
          render={({ field: { onChange, value } }) => (
            <SummaryBox
              creationMode="REQUISITION_CREATION"
              email={requesterEmail}
              grandTotal={grandTotal}
              // Auto-approved requisitions always have FMS integration
              hasFMSIntegration={hasFMSIntegration || isAutoApprovedRequisition}
              isLoading={loading}
              name={requesterName}
              nrLineItems={lineItems.length}
              nrRfqItems={rfqItems.length}
              nrExtraItems={extraItems.length}
              onPoNumberChange={onChange}
              onSubmit={handleSubmit(submit)}
              poNumber={value}
              poNumberError={errors.customerOrderId?.message}
              port={port}
              register={register}
              registrationName="customerOrderId"
              validationErrorMessage={validationErrorMessage}
              vessel={vessel}
              renderAttentionInfo={renderAttentionInfo}
              isAutoApprovedRequisition={isAutoApprovedRequisition}
              orderName={orderName}
              supplier={hasSelectSupplierFeature ? supplier : undefined}
            />
          )}
        />
      </div>
    </>
  );
};

export default RequisitionInfo;
