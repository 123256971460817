import { useCallback } from "react";

import {
  BASKET_ANSWERS_FORM_STORAGE_KEY,
  BASKET_STORAGE_KEY,
  NETWORK_TOGGLE_MODE_STORAGE_KEY,
  PUNCHOUT_SESSION_ID_STORAGE_KEY,
  SUPPLIER_ID_STORAGE_KEY,
  VESSEL_STORAGE_KEY,
  VESSEL_TOKEN_STORAGE_KEY,
} from "src/config/constants";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useNetworkToggle } from "src/contexts/NetworkToggle";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";

export const useAppCleanup = () => {
  const { clearOfflineCapabilitiesState } = useOfflineCapabilities();
  const { clearForcedNetworkState } = useNetworkDetector();
  const { setToggleOnline } = useNetworkToggle();

  const cleanupVesselToken = useCallback(() => {
    localStorage.removeItem(VESSEL_TOKEN_STORAGE_KEY);
  }, []);

  const cleanupVesselSession = useCallback(() => {
    // Do not remove the vessel token here!
    // Note: GatherOut settings are kept inside the VESSEL_STORAGE_KEY LS entry, so they do not need a separate cleanup fn
    localStorage.removeItem(VESSEL_STORAGE_KEY);
    localStorage.removeItem(BASKET_STORAGE_KEY);
    localStorage.removeItem(BASKET_ANSWERS_FORM_STORAGE_KEY);
    localStorage.removeItem(NETWORK_TOGGLE_MODE_STORAGE_KEY);
    // Not manually clearing the basket's & app's state since we reload the page anyway
  }, []);

  const cleanupPunchoutSession = useCallback(() => {
    localStorage.removeItem(PUNCHOUT_SESSION_ID_STORAGE_KEY);
    localStorage.removeItem(SUPPLIER_ID_STORAGE_KEY);
  }, []);

  // Resets all values related to `offlineCapabilities` in localStorage and app's state
  const cleanupOfflineState = useCallback(() => {
    clearForcedNetworkState();
    clearOfflineCapabilitiesState();
    setToggleOnline();
  }, [clearForcedNetworkState, clearOfflineCapabilitiesState, setToggleOnline]);

  return {
    cleanupVesselToken,
    cleanupVesselSession,
    cleanupPunchoutSession,
    cleanupOfflineState,
  };
};
