import { z } from "zod";

export const SkuTemperatureConditionSchema = z.union([
  z.literal("AMBIENT"),
  z.literal("CHILLED"),
  z.literal("DEEP_FROZEN"),
  z.literal("FRESH"),
  z.literal("FROZEN"),
  z.literal("THAWED"),
  z.literal("DRY"),
  z.literal("UNKNOWN"),
]);
