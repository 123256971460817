import { DefaultError, UseMutationOptions, useMutation } from "@tanstack/react-query";

import { updateRequisition } from "src/api/updateRequisition";
import { UpdateRequisitionParams } from "src/models";

export const useUpdateRequisitionMutation = (
  options: UseMutationOptions<unknown, DefaultError, UpdateRequisitionParams> = {}
) => {
  return useMutation<unknown, DefaultError, UpdateRequisitionParams>({
    mutationKey: ["updateRequisition"],
    mutationFn: updateRequisition,
    ...options,
  });
};
