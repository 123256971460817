import { z } from "zod";

import { SkuTemperatureConditionSchema } from "./SkuTemperatureConditionSchema";
import { optionalNonEmptyString } from "./common";

export const LiteSkuStorageInformationSchema = z.object({
  shelfLife: optionalNonEmptyString(),
  minShelfLife: z.number().nonnegative().optional(),
  storageInformation: optionalNonEmptyString(),
  temperatureCondition: SkuTemperatureConditionSchema.optional(),
});
