import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { GatherOutConfig } from "@web/common";
import { Loading } from "@web/ui";

import { useAppStateContext } from "src/contexts/AppStateContext";
import {
  LocalGatherOutService,
  LocalLiteGatherOutAvailableItem,
  useValidatedGatherOutBasketQuery,
} from "src/domain";
import useBasket from "src/hooks/useBasket";
import { LocalProductService } from "src/services/LocalProductService";

export const GatherOutEntryPoint = () => {
  const [appState, dispatch] = useAppStateContext();
  const [gatherOutConfig, setGatherOutConfig] = useState<GatherOutConfig | undefined>();
  const { clearBasket, pushCatalogItemToBasket, setBasketExtraItems } = useBasket();

  useEffect(() => {
    // Get typesafe config
    setGatherOutConfig(
      LocalGatherOutService.convertFromAppStateToValidatedGatherOutConfig(appState.gatherOutConfig)
    );
  }, [appState.gatherOutConfig]);

  const isRegatherOutFlow = LocalGatherOutService.getIsRegatherOutFlow(
    gatherOutConfig?.gatherOutFlow
  );
  const entryPath = LocalGatherOutService.getGatherOutEntryPath(gatherOutConfig);

  const { data: gatherOutBasket, isLoading: isGatherOutBasketLoading } =
    useValidatedGatherOutBasketQuery({
      basketId: gatherOutConfig?.basketId || "",
      gatherOutTimestamp: gatherOutConfig?.timestamp || "",
      enabled: isRegatherOutFlow,
    });

  useEffect(() => {
    if (!isRegatherOutFlow || !gatherOutBasket) {
      return;
    }

    dispatch({
      type: "setRegatherOutSetup",
      value: {
        orderType: gatherOutBasket.orderType,
        portId: gatherOutBasket.port.id,
        supplierId: gatherOutBasket.supplier.id,
      },
    });

    clearBasket();

    gatherOutBasket.availableItems.forEach((product: LocalLiteGatherOutAvailableItem) => {
      pushCatalogItemToBasket(
        LocalProductService.convertGatherOutAvailableItemToBasketEntry(product)
      );
    });

    setBasketExtraItems(gatherOutBasket.extraItems);
  }, [
    clearBasket,
    dispatch,
    gatherOutBasket,
    isRegatherOutFlow,
    pushCatalogItemToBasket,
    setBasketExtraItems,
  ]);

  // Wait for the state to update so all business rules can be applied without
  // navigating away by mistake. We do not need to recover from this in the
  // current app version.
  if (!gatherOutConfig) {
    return <Loading />;
  }

  // Wait for the basket load if it's a RegatherOut flow
  if (isRegatherOutFlow && isGatherOutBasketLoading) {
    return <Loading />;
  }

  return <Navigate to={entryPath || "/404"} replace />;
};
