import { PropsWithChildren } from "react";

import { Loading } from "@web/ui";

import useConfigurationQuery from "../hooks/useConfigurationQuery";

export const ConfigurationWrapper = ({ children }: PropsWithChildren) => {
  const configurationQuery = useConfigurationQuery();

  if (configurationQuery.isPending || configurationQuery.isFetching) {
    return <Loading />;
  }

  return <>{children}</>;
};
