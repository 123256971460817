import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { GatherOutConfig } from "@web/common";

import { RoutesConfig } from "src/config/routes";
import { useToastMessage } from "src/hooks/useToastMessage";
import { useUpdateRequisitionMutation } from "src/hooks/useUpdateRequisitionMutation";

import { GatherOutSubmissionData } from "../models";
import { LocalGatherOutService } from "../services";

type Params = {
  gatherOutConfig: GatherOutConfig | undefined;
};

type UseGatherOutSubmit = {
  isPending: boolean;
  submitGatherOut: (submissionData: GatherOutSubmissionData) => void;
};

export const useGatherOutSubmit = ({ gatherOutConfig }: Params): UseGatherOutSubmit => {
  const { setToastMessage } = useToastMessage();
  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate();

  const { mutate: updateRequisition } = useUpdateRequisitionMutation({
    onSuccess: () => {
      navigate(RoutesConfig.submitGatherOutExitPoint);
    },
    onError: () => {
      setIsPending(false);
      setToastMessage({
        type: "failure",
        message:
          "There was an error while submitting the Gather Out session. Please try again and if that does not work, please contact our Customer Support team.",
      });
    },
  });

  const submitGatherOut = useCallback(
    (submissionData: GatherOutSubmissionData) => {
      if (!gatherOutConfig || !gatherOutConfig.basketId) {
        setToastMessage({
          type: "failure",
          message:
            "There was an error while submitting the Gather Out session - issue with Gather Out configuration (1). Please try again and if that does not work, please contact our Customer Support team.",
        });
        return;
      }

      const validatedSubmissionData =
        LocalGatherOutService.convertGatherOutSubmissionDataToValidatedGatherOutSubmissionData(
          submissionData
        );

      // Setting manually, because we want to keep the pending state for the redirect to the external system
      setIsPending(true);
      updateRequisition({
        requisitionId: gatherOutConfig.basketId,
        requestBody: validatedSubmissionData,
      });
    },
    [gatherOutConfig, setToastMessage, updateRequisition]
  );

  return {
    submitGatherOut,
    isPending,
  };
};
