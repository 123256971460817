import { NoteBox } from "@web/common/components";
import { ActivityBox } from "@web/common/layouts";
import Banner from "@web/common/layouts/Banner";
import { PriceBox } from "@web/common/layouts/PriceBox";
import { OrderRequisition } from "@web/common/network";
import { containerPadding } from "@web/ui";

import { TopBarController } from "src/components/TopBar";

interface Props {
  children: React.ReactNode;
  order: OrderRequisition;
  actionBar?: React.ReactNode;
  renderAttentionInfo?: () => React.ReactElement | null;
}

export const OrderLayout: React.FC<Props> = ({
  children,
  order,
  actionBar,
  renderAttentionInfo,
}) => {
  return (
    <div className="flex flex-col min-h-full">
      <TopBarController />
      <Banner status={order.status} />
      <div className="bg-neutral_100 min-h-full flex flex-col flex-grow">
        {actionBar}
        <div className={`${containerPadding} flex`}>
          <div className="w-4/6 mr-3">{children}</div>
          <div className="ml-7 h-min w-1/3">
            <PriceBox order={order} className="mb-3" />
            <ActivityBox order={order} renderAttentionInfo={renderAttentionInfo} />
            <NoteBox order={order} />
          </div>
        </div>
      </div>
    </div>
  );
};
