import { z } from "zod";

export const OrderStatusSchema = z.union([
  z.literal("CANCELLED"),
  z.literal("PENDING_CANCELLATION"),
  z.literal("SUPPLIER_ACKNOWLEDGED"),
  z.literal("SUPPLIER_CONFIRMED"),
  z.literal("SUPPLIER_PENDING"),
  z.literal("SUPPLIER_REJECTED"),
  z.literal("SUPPLIER_DELIVERED"),
  z.literal("PENDING_PURCHASER_APPROVAL"),
  z.literal("PURCHASER_REJECTED"),
  z.literal("SUPPLIER_QUOTE_PENDING"),
  z.literal("SUPPLIER_QUOTE_ACKNOWLEDGED"),
  z.literal("SUPPLIER_QUOTE_PROVIDED"),
  z.literal("SUPPLIER_QUOTE_REJECTION_ACKNOWLEDGED"),
  z.literal("QUOTE_EXPIRED"),
  z.literal("QUOTE_REJECTED"),
  z.literal("DRAFT_CREATED"),
  z.literal("DRAFT_DELETED"),
  // #12623: Remove CLOSED order status
  z.literal("CLOSED"),
]);
