import { z } from "zod";

import { assertTypeEquality } from "@web/utils";

import {
  LitePortSchema,
  LiteSupplierInformationSchema,
  OrderStocktakeTypeSchema,
} from "src/models";
import { LiteGatheroutBasket } from "src/typegens";

import { LiteGatherOutAvailableItemSchema } from "./LiteGatherOutAvailableItemSchema";
import { LiteGatherOutExtraItemSchema } from "./LiteGatherOutExtraItemSchema";
import { LiteGatherOutUnavailableItemSchema } from "./LiteGatherOutUnavailableItemSchema";

export const LiteGatherOutBasketSchema = z.object({
  availableItems: z.array(LiteGatherOutAvailableItemSchema),
  unavailableItems: z.array(LiteGatherOutUnavailableItemSchema),
  extraItems: z.array(LiteGatherOutExtraItemSchema),
  port: LitePortSchema,
  supplier: LiteSupplierInformationSchema,
  orderType: OrderStocktakeTypeSchema,
});

export type LocalLiteGatherOutBasket = z.infer<typeof LiteGatherOutBasketSchema>;

assertTypeEquality<LocalLiteGatherOutBasket, LiteGatheroutBasket, LocalLiteGatherOutBasket>();
