import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useAppArea } from "src/hooks/useAppArea";

export const MainPage = () => {
  const navigate = useNavigate();
  const { overviewPath } = useAppArea();
  const [{ isPunchoutSession, isGatherOutSession }] = useAppStateContext();

  useEffect(() => {
    if (isPunchoutSession) {
      navigate(RoutesConfig.gatherSetup, { replace: true });
      return;
    }

    if (isGatherOutSession) {
      navigate(RoutesConfig.gatherOutEntryPoint, { replace: true });
      return;
    }

    navigate(overviewPath, { replace: true });
  }, [isGatherOutSession, isPunchoutSession, navigate, overviewPath]);

  return null;
};
