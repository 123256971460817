/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { LiteCreateRequisitionRequest } from "../models/LiteCreateRequisitionRequest";
import type { LiteOrderRequisition } from "../models/LiteOrderRequisition";
import type { LiteUpdateRequisitionRequest } from "../models/LiteUpdateRequisitionRequest";

export class RequisitionsService {
  /**
   * @returns LiteOrderRequisition OK
   * @throws ApiError
   */
  public static updateRequisition(
    {
      requisitionId,
      requestBody,
    }: {
      requisitionId: string;
      requestBody: LiteUpdateRequisitionRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<LiteOrderRequisition> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/lite/requisitions/{requisitionId}",
      path: {
        requisitionId: requisitionId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns LiteOrderRequisition OK
   * @throws ApiError
   */
  public static cancelRequisition(
    {
      requisitionId,
    }: {
      requisitionId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<LiteOrderRequisition> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/lite/requisitions/{requisitionId}/cancellation",
      path: {
        requisitionId: requisitionId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns LiteOrderRequisition OK
   * @throws ApiError
   */
  public static createRequisitionLatest(
    {
      requestBody,
    }: {
      requestBody: LiteCreateRequisitionRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Array<LiteOrderRequisition>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/lite/requisitions",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
