import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { GatherOutConfig } from "@web/common";
import { DangerConfirmationModal, Modal } from "@web/ui";

import { RoutesConfig } from "src/config/routes";

import { CancelGatherOutModalLabels } from "../models";
import { LocalGatherOutService } from "../services";

type Params = {
  gatherOutConfig: GatherOutConfig | undefined;
};

type UseGatherOutCancel = {
  cancelGatherOut: () => void;
  cancelGatherOutLabel: string;
  CancelGatherOutConfirmationModal: FunctionComponent<{
    onConfirmed: (onCancelGatherOutConfirmed: () => void) => void;
  }>;
};

export const useGatherOutCancel = ({ gatherOutConfig }: Params): UseGatherOutCancel => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const navigate = useNavigate();

  const cancelGatherOut = useCallback(() => {
    setIsConfirmationModalOpen(true);
  }, []);

  const onCancelGatherOutConfirmed = useCallback(() => {
    navigate(RoutesConfig.cancelGatherOutExitPoint);
  }, [navigate]);

  const cancelGatherOutLabel = LocalGatherOutService.getGatherOutCancelLabel(gatherOutConfig);
  const cancelGatherOutModalLabels: CancelGatherOutModalLabels = useMemo(
    () => LocalGatherOutService.getGatherOutCancelModalLabels(gatherOutConfig),
    [gatherOutConfig]
  );

  const closeConfirmationModal = useCallback(() => {
    setIsConfirmationModalOpen(false);
  }, []);

  const CancelGatherOutConfirmationModal = useCallback(
    ({ onConfirmed }: { onConfirmed: (onCancelGatherOutConfirmed: () => void) => void }) => {
      return (
        <Modal isOpen={isConfirmationModalOpen} closeModal={closeConfirmationModal}>
          <DangerConfirmationModal
            onConfirm={() => onConfirmed(onCancelGatherOutConfirmed)}
            onClose={closeConfirmationModal}
            title={cancelGatherOutModalLabels.title}
            subtitle={cancelGatherOutModalLabels.subtitle}
            confirmButtonLabel={cancelGatherOutModalLabels.confirm}
            closeButtonLabel={cancelGatherOutModalLabels.abort}
            testIdPrefix="cancelGatherOutConfirmationModal"
          />
        </Modal>
      );
    },
    [
      cancelGatherOutModalLabels.abort,
      cancelGatherOutModalLabels.confirm,
      cancelGatherOutModalLabels.subtitle,
      cancelGatherOutModalLabels.title,
      closeConfirmationModal,
      isConfirmationModalOpen,
      onCancelGatherOutConfirmed,
    ]
  );

  return {
    cancelGatherOut,
    cancelGatherOutLabel,
    CancelGatherOutConfirmationModal,
  };
};
