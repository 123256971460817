import { PropsWithChildren } from "react";

import { Toast } from "@web/ui";

import { useAppStateContext } from "../AppStateContext";

export const SystemMessagesProvider = ({ children }: PropsWithChildren) => {
  const [{ systemMessages }, dispatch] = useAppStateContext();
  const currentMessage = systemMessages[0];

  const clearMessage = (id: string) => dispatch({ type: "clearSystemMessage", value: { id } });

  return (
    <>
      {children}
      {currentMessage && <Toast toastMessage={currentMessage} onDismissToast={clearMessage} />}
    </>
  );
};
