import _debounce from "lodash/debounce";
import { useCallback, useEffect } from "react";
import { Control, UseFormGetValues, useWatch } from "react-hook-form";

import { LocalBasketExtraItemForm, LocalBasketForm, LocalLiteOrderExtraItem } from "src/models";
import { LocalProductService } from "src/services/LocalProductService";

import useBasket from "./useBasket";

const COPY_FORM_VALUES_DEBOUNCED = 50;

type Params = {
  control: Control<LocalBasketForm, unknown>;
  getValues: UseFormGetValues<LocalBasketForm>;
  defaultValues: LocalBasketForm;
};

type UseAutosaveStocktakeDraftForm = {
  setFormExtraItemsInState: (extraItems: LocalBasketExtraItemForm[]) => LocalLiteOrderExtraItem[];
  abortCopy: () => void;
};

export const useCopyExtraItemsFormValuesToBasketState = ({
  control,
  getValues,
  defaultValues,
}: Params): UseAutosaveStocktakeDraftForm => {
  const { setBasketExtraItems } = useBasket();

  const changedData = useWatch({ control, defaultValue: defaultValues });

  const setFormExtraItemsInState = useCallback(
    (extraItems: LocalBasketExtraItemForm[]) => {
      const convertedExtraItems: LocalLiteOrderExtraItem[] = extraItems.map((item) =>
        LocalProductService.convertFromBasketExtraItemFormToLocalExtraItem(item)
      );
      setBasketExtraItems(convertedExtraItems);
      return convertedExtraItems;
    },
    [setBasketExtraItems]
  );

  const copyValues = useCallback(() => {
    const formData = getValues();
    setFormExtraItemsInState(formData.extraItems);
  }, [getValues, setFormExtraItemsInState]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const copyValuesDebounced = useCallback(
    _debounce(() => {
      copyValues();
    }, COPY_FORM_VALUES_DEBOUNCED),
    [copyValues]
  );

  const abortCopyValuesDebounced = useCallback(
    () => copyValuesDebounced.cancel(),
    [copyValuesDebounced]
  );

  useEffect(() => {
    copyValuesDebounced();
    return () => abortCopyValuesDebounced();
  }, [abortCopyValuesDebounced, changedData, copyValuesDebounced]);

  return {
    setFormExtraItemsInState,
    abortCopy: abortCopyValuesDebounced,
  };
};
