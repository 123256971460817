import { useQuery, useQueryClient } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useCallback, useMemo } from "react";

import { getGatherOutBasket } from "../api";
import { LocalLiteGatherOutBasket } from "../models";
import { LocalGatherOutService } from "../services";

export const GATHEROUT_BASKET_QUERY_KEY_BASE = "gatherOutBasket";

const getQueryKey = (basketId: string, gatherOutTimestamp: string) => [
  GATHEROUT_BASKET_QUERY_KEY_BASE,
  basketId,
  gatherOutTimestamp,
];

type UseGatherOutBasketQuery = UseQueryResult<LocalLiteGatherOutBasket>;

const useGatherOutBasketQuery = <T = LocalLiteGatherOutBasket>({
  basketId,
  gatherOutTimestamp,
  enabled,
  select,
}: {
  basketId: string;
  gatherOutTimestamp: string;
  enabled: boolean;
  select: (
    data: LocalLiteGatherOutBasket
  ) => T extends LocalLiteGatherOutBasket ? T : LocalLiteGatherOutBasket;
}): UseGatherOutBasketQuery => {
  const queryKey = useMemo(
    () => getQueryKey(basketId, gatherOutTimestamp),
    [basketId, gatherOutTimestamp]
  );
  return useQuery<LocalLiteGatherOutBasket>({
    queryKey,
    queryFn: ({ signal }) => getGatherOutBasket({ basketId }, { signal }),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnReconnect: false,
    enabled,
    select,
  });
};

type UseGatherOutBasketQueryHelpers = {
  invalidate: () => void;
};

export const useGatherOutBasketQueryHelpers = ({
  basketId,
  gatherOutTimestamp,
}: {
  basketId: string;
  gatherOutTimestamp: string;
}): UseGatherOutBasketQueryHelpers => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => getQueryKey(basketId, gatherOutTimestamp),
    [basketId, gatherOutTimestamp]
  );

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey,
      }),
    [queryClient, queryKey]
  );

  return {
    invalidate,
  };
};

type UseValidatedGatherOutBasketQuery = UseQueryResult<LocalLiteGatherOutBasket>;

export const useValidatedGatherOutBasketQuery = ({
  basketId,
  gatherOutTimestamp,
  enabled,
}: {
  basketId: string;
  gatherOutTimestamp: string;
  enabled: boolean;
}): UseValidatedGatherOutBasketQuery => {
  return useGatherOutBasketQuery({
    basketId,
    gatherOutTimestamp,
    enabled,
    select: (data): LocalLiteGatherOutBasket =>
      LocalGatherOutService.convertFromApiToValidatedGatherOutBasket(data),
  });
};
