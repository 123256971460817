import { zodResolver } from "@hookform/resolvers/zod";
import { useIsMutating } from "@tanstack/react-query";
import { useFlag } from "@unleash/proxy-client-react";
import classnames from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  ActionBar,
  ActionBarPrimaryButtonSettings,
  Heading,
  Label,
  Paragraph,
  containerPadding,
} from "@web/ui";
import { formatMoney, formatNumber } from "@web/utils";

import { OrderDraftSavedInfo } from "src/components/OrderDraftInfo";
import RequestProductInline from "src/components/RequestProductInline";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { GatherOutSubmissionData, useGatherOutCancel, useGatherOutSubmit } from "src/domain";
import { LEGACY_useCreateOrderDraftMutation } from "src/hooks/LEGACY_useCreateOrderDraftMutation";
import { LEGACY_useOrderDraft } from "src/hooks/LEGACY_useOrderDraft";
import { useCreateOrderDraftMutation } from "src/hooks/orderDrafts/useCreateOrderDraftMutation";
import { usePrepareOrderDraft } from "src/hooks/orderDrafts/usePrepareOrderDraft";
import useBasket from "src/hooks/useBasket";
import { useCopyExtraItemsFormValuesToBasketState } from "src/hooks/useCopyExtraItemsFormValuesToBasketState";
import { useNetworkDependentAction } from "src/hooks/useNetworkDependentAction";
import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";
import { usePunchoutOrder } from "src/hooks/usePunchoutOrder";
import { useShareBasketMutation } from "src/hooks/useShareBasketMutation";
import { useToastMessage } from "src/hooks/useToastMessage";
import { BasketFormSchema, LocalBasketForm } from "src/models";
import { LocalProductService } from "src/services/LocalProductService";
import { Configuration } from "src/state/models";

import {
  BasketAnswersFormModalController,
  BasketEmpty,
  BasketExtraItemsSection,
  BasketHeader,
  LineItemFastGather,
  RfqItem,
} from "./components";

const getBasketSubmitMode = (
  isPunchoutSession: boolean,
  isGatherOutSession: boolean,
  configuration?: Configuration
): "PUNCHOUT" | "AUTO_APPROVAL" | "ORDER" | "REQUISITION" | "GATHEROUT" | undefined => {
  if (!configuration) {
    return;
  }

  if (isPunchoutSession) {
    return "PUNCHOUT";
  }
  if (isGatherOutSession) {
    return "GATHEROUT";
  }
  if (configuration?.canCreateOrder && configuration.isAutoApprovedRequisition) {
    return "AUTO_APPROVAL";
  }
  if (configuration?.canCreateOrder && !configuration.isAutoApprovedRequisition) {
    return "ORDER";
  }
  if (configuration?.canCreateRequisition) {
    return "REQUISITION";
  }
};

const postForm = (path: string, params: Record<string, string>, method = "post") => {
  const form = document.createElement("form");
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (key in params) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = "data";
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
};

export const Basket = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [
    {
      configuration,
      isPunchoutSession,
      isGatherOutSession,
      forcePreconfigureOrderSetup,
      orderType,
      port,
      supplier,
      gatherOutConfig,
    },
  ] = useAppStateContext();

  useEffect(() => {
    if (forcePreconfigureOrderSetup) {
      navigate(RoutesConfig.gatherSetup);
    }
  }, [forcePreconfigureOrderSetup, navigate]);

  const {
    lineItems,
    rfqItems,
    extraItems,
    draft,
    setNewDraft,
    clearDraft,
    grandTotal,
    lastUpdated: basketLastUpdated,
    getOrderItems,
  } = useBasket();
  const hasRfqFeature =
    configuration?.fleet.permissions.includes("CREATE_REQUISITION") &&
    configuration.fleet.allow.createRfq;
  const areOrderExtraItemsEnabled = !!configuration?.fleet.allow.orderExtraItems;
  const hasSelectSupplierFeature = useFlag("select-supplier");

  const [isPunchoutOrderPending, setIsPunchoutOrderPending] = useState(false);
  const [isBasketAnswersFormModalOpen, setIsBasketAnswersFormModalOpen] = useState(false);
  const { setToastMessage } = useToastMessage();
  const { allowOnlineOnly, AllowOnlineOnlyWarningModal } = useNetworkDependentAction();

  const numberOfItems = lineItems.length + rfqItems.length;
  const numberOfRfqItems = rfqItems.length;
  const numberOfExtraItems = extraItems.length;
  const hasLineItems = numberOfItems > 0;
  const hasLineOrRfqItems = numberOfItems > 0 || numberOfRfqItems > 0;
  const isBasketEmpty = numberOfItems === 0 && numberOfRfqItems === 0 && numberOfExtraItems === 0;
  const unitOfMeasures = configuration?.unitOfMeasures || [];
  const isFromDraft = Boolean(draft);
  const isBasketAnswersFormRequired = Boolean(configuration?.basketAnswersForm);

  const { isOfflineDraftEnabled } = useOfflineDraftEnabled();

  const isCreatingOnlineDraft = !!useIsMutating({
    mutationKey: ["LEGACY_createDraft"],
  });
  const isUpdatingOnlineDraft = !!useIsMutating({
    mutationKey: ["LEGACY_updateDraft"],
  });

  const isCreatingOfflineDraft = !!useIsMutating({
    mutationKey: ["createDraft"],
  });
  const isUpdatingOfflineDraft = !!useIsMutating({
    mutationKey: ["updateDraft"],
  });

  const isCreatingDraft = isOfflineDraftEnabled ? isCreatingOfflineDraft : isCreatingOnlineDraft;
  const isUpdatingDraft = isOfflineDraftEnabled ? isUpdatingOfflineDraft : isUpdatingOnlineDraft;

  const openBasketAnswersFormModal = () => setIsBasketAnswersFormModalOpen(true);
  const closeBasketAnswersFormModal = () => setIsBasketAnswersFormModalOpen(false);

  const catalogItems = lineItems.map((lineItem) => ({
    variantId: lineItem.sku.id,
    quantity: lineItem.quantity,
  }));

  const formDefaultValues = useRef<LocalBasketForm>({
    extraItems: extraItems.map((item) =>
      LocalProductService.convertFromLocalExtraItemToBasketExtraItemForm(item)
    ),
  });
  const formMethods = useForm<LocalBasketForm>({
    resolver: zodResolver(BasketFormSchema),
    // `mode` is set to `onSubmit` due to performance reasons
    mode: "onSubmit",
    defaultValues: formDefaultValues.current,
  });
  const { control, handleSubmit, reset: resetForm, getValues } = formMethods;
  const {
    fields: formExtraItems,
    prepend: prependFormExtraItem,
    remove: removeFormExtraItem,
  } = useFieldArray({
    control,
    name: "extraItems",
    // Set custom key where react-hook-form generates its internal ID for each collection element
    // See also: https://spectrum.chat/react-hook-form/help/useformarray-is-overriding-the-id-on-my-data~a976690e-dfdd-4736-81c7-1a740b0b3fc7
    keyName: "key",
  });

  const {
    setFormExtraItemsInState: copyExtraItemsFormToBasketState,
    abortCopy: abortAutoCopyExtraItemsFormToBasketState,
  } = useCopyExtraItemsFormValuesToBasketState({
    control,
    getValues,
    defaultValues: formDefaultValues.current,
  });

  const sendOrderMessage = usePunchoutOrder({ catalogItems });

  const { submitGatherOut, isPending: isGatherOutSubmissionPending } = useGatherOutSubmit({
    gatherOutConfig,
  });
  const { cancelGatherOut, cancelGatherOutLabel, CancelGatherOutConfirmationModal } =
    useGatherOutCancel({
      gatherOutConfig,
    });

  const handleSubmitGatherOut = useCallback(
    (submissionData: GatherOutSubmissionData) => {
      allowOnlineOnly(async () => {
        submitGatherOut(submissionData);
      });
    },
    [allowOnlineOnly, submitGatherOut]
  );

  const handleCancelGatherOutConfirmed = useCallback(
    (onCancelGatherOutConfirmed: () => void) => {
      allowOnlineOnly(async () => {
        onCancelGatherOutConfirmed();
      });
    },
    [allowOnlineOnly]
  );

  const handleSendPunchoutOrderMessage = useCallback(() => {
    allowOnlineOnly(async () => {
      setIsPunchoutOrderPending(true);
      const response = await sendOrderMessage();
      postForm(response.url, { value: response.message });
    });
  }, [allowOnlineOnly, sendOrderMessage]);

  const createOrder = useCallback(() => {
    navigate(`${RoutesConfig.orderInfo}${isFromDraft ? "?from=draft" : ""}`);
  }, [isFromDraft, navigate]);

  const createRequisition = useCallback(() => {
    isBasketAnswersFormRequired
      ? openBasketAnswersFormModal()
      : navigate(`${RoutesConfig.requisitionInfo}${isFromDraft ? "?from=draft" : ""}`);
  }, [isBasketAnswersFormRequired, isFromDraft, navigate]);

  const handleValidBasketSubmit: SubmitHandler<LocalBasketForm> = useCallback(
    (validatedFormData: LocalBasketForm) => {
      const finalFormData: LocalBasketForm = {
        ...validatedFormData,
        // Handle an edge case, where the feature may have been disabled for the vessel in the meantime
        extraItems: areOrderExtraItemsEnabled ? validatedFormData.extraItems : [],
      };
      resetForm(finalFormData);
      abortAutoCopyExtraItemsFormToBasketState();
      const convertedExtraItems = copyExtraItemsFormToBasketState(finalFormData.extraItems);

      const submitMode = getBasketSubmitMode(isPunchoutSession, isGatherOutSession, configuration);
      switch (submitMode) {
        case "PUNCHOUT":
          handleSendPunchoutOrderMessage();
          break;
        case "GATHEROUT":
          handleSubmitGatherOut({
            catalogItems: getOrderItems(),
            extraItems: convertedExtraItems,
          });
          break;
        case "AUTO_APPROVAL":
          createRequisition();
          break;
        case "ORDER":
          createOrder();
          break;
        case "REQUISITION":
          createRequisition();
          break;
        default:
          setToastMessage({
            type: "failure",
            message:
              "There was an error while submitting your basket. Please contact Customer Support.",
          });
          break;
      }
    },
    [
      areOrderExtraItemsEnabled,
      resetForm,
      abortAutoCopyExtraItemsFormToBasketState,
      copyExtraItemsFormToBasketState,
      isPunchoutSession,
      isGatherOutSession,
      configuration,
      handleSendPunchoutOrderMessage,
      handleSubmitGatherOut,
      getOrderItems,
      createRequisition,
      createOrder,
      setToastMessage,
    ]
  );

  const handleInvalidBasketSubmit: SubmitErrorHandler<LocalBasketForm> = useCallback(
    (errors) => {
      setToastMessage({
        type: "failure",
        message: "Your Basket contains some errors. Please fix them and try again.",
      });
      // May come in handy if user gets stuck
      console.error("Your form contains the following errors:", errors);
    },
    [setToastMessage]
  );

  const handleCreateDraftSuccess = ({
    draftId,
    updatedAt,
  }: {
    draftId: string;
    updatedAt: string;
  }) => {
    setNewDraft({ draftId, updatedAt });
  };

  const { getOrderDraftData: getDraftData } = LEGACY_useOrderDraft();
  const { mutate } = LEGACY_useCreateOrderDraftMutation(
    (orderRequisition) =>
      handleCreateDraftSuccess({
        draftId: orderRequisition.id,
        updatedAt: orderRequisition.updatedAt,
      }),
    () => {
      // Fixes #11926
      clearDraft();
    }
  );

  const { getPartialOrderDraftData } = usePrepareOrderDraft();
  const { mutate: createDraft } = useCreateOrderDraftMutation({
    onSuccess: (createdDraft) =>
      handleCreateDraftSuccess({ draftId: createdDraft.id, updatedAt: createdDraft.updatedAt }),
    hasSuccessMessage: true,
    hasErrorMessage: true,
  });

  const { mutateAsync: createDraftAsync } = useCreateOrderDraftMutation({
    onSuccess: (createdDraft) =>
      handleCreateDraftSuccess({ draftId: createdDraft.id, updatedAt: createdDraft.updatedAt }),
  });

  const handleCreatingDraft = useMemo(() => {
    return () => {
      // TODO #5641: Remove setting draft here along with Online Draft logic
      // Related to #8117: Dirty fix to prevent overwriting the draft after it was cleared in some edge cases.
      // Dirty fix because online draft is a legacy feature and Offline Draft is not susceptible to this problem.
      setNewDraft({ draftId: "", updatedAt: new Date().toISOString(), status: "CREATION_STARTED" });

      if (isOfflineDraftEnabled) {
        const draftToCreate = getPartialOrderDraftData();
        createDraft(draftToCreate);
        return;
      }

      mutate(getDraftData());
    };
  }, [
    setNewDraft,
    isOfflineDraftEnabled,
    mutate,
    getDraftData,
    getPartialOrderDraftData,
    createDraft,
  ]);

  const { mutate: shareBasket, isPending: isSharingBasket } = useShareBasketMutation({
    onSuccess: () => {
      setToastMessage({
        type: "success",
        message:
          "Success! Your basket has been sent to your email and is ready to be shared with your colleagues.",
      });
    },
    onError: () => {
      setToastMessage({
        type: "failure",
        message: "This did not work. Please try sharing the basket via email again.",
      });
    },
  });

  const { mutate: shareBasketAfterDraftCreation, isPending: isSharingBasketAfterDraftCreation } =
    useShareBasketMutation({
      onSuccess: () => {
        setToastMessage({
          type: "success",
          message:
            "Success! A draft has been created and your basket has been sent to your email and is ready to be shared with your colleagues.",
        });
      },
      onError: () => {
        setToastMessage({
          type: "failure",
          message:
            "A draft has been created, but we failed to share your basket. Please try again and if that does not work, please contact our Customer Support team.",
        });
      },
    });

  const handleShareBasket = useCallback(() => {
    if (isSharingBasket || isSharingBasketAfterDraftCreation) {
      return;
    }

    // This is only to make the compiler happy.
    // Port being defined is guarded by useEffect checking for forcePreconfigureOrderSetup
    // and redirecting away if anything is wrong.
    // TODO #11983: Also check for orderType & supplier when those become required fields in the models
    if (!port) {
      throw new Error("No port selected");
    }
    if (hasSelectSupplierFeature && !supplier) {
      throw new Error("No supplier selected");
    }

    allowOnlineOnly(async () => {
      if (!draft) {
        const draftToCreate = getPartialOrderDraftData();
        try {
          // Keep it here for compatibility reason.
          // TODO #5641: Remove this action and fall back to `setDraft` everywhere when removing Online Draft logic
          setNewDraft({
            draftId: "",
            updatedAt: new Date().toISOString(),
            status: "CREATION_STARTED",
          });
          await createDraftAsync(draftToCreate);
          shareBasketAfterDraftCreation({
            orderType,
            portId: port.id,
            supplierId: hasSelectSupplierFeature ? supplier?.id : undefined,
            items: getOrderItems(),
            extraItems,
          });
        } catch (err) {
          // Error handling is happening in `onError` in respective hooks, just log error here
          console.error(err);
        }
      } else {
        shareBasket({
          orderType,
          portId: port.id,
          supplierId: hasSelectSupplierFeature ? supplier?.id : undefined,
          items: getOrderItems(),
          extraItems,
        });
      }
    });
  }, [
    isSharingBasket,
    isSharingBasketAfterDraftCreation,
    port,
    hasSelectSupplierFeature,
    supplier,
    allowOnlineOnly,
    draft,
    getPartialOrderDraftData,
    setNewDraft,
    createDraftAsync,
    shareBasketAfterDraftCreation,
    orderType,
    getOrderItems,
    extraItems,
    shareBasket,
  ]);

  const actionBarPrimaryButtonSettings: ActionBarPrimaryButtonSettings | undefined = useMemo(() => {
    const submitMode = getBasketSubmitMode(isPunchoutSession, isGatherOutSession, configuration);
    const commonConfig: Partial<ActionBarPrimaryButtonSettings> = {
      isDisabled: !hasLineItems,
      tooltipText: !hasLineItems
        ? "Add at least 1 product from the catalog to the basket to proceed"
        : "",
    };
    switch (submitMode) {
      case "PUNCHOUT":
        return {
          ...commonConfig,
          title: "Send to FMS",
          type: "submit",
          isLoading: isPunchoutOrderPending,
          testId: "sendToFMSButton",
        };
      case "GATHEROUT":
        return {
          ...commonConfig,
          title: "Submit Basket",
          type: "submit",
          isLoading: isGatherOutSubmissionPending,
          testId: "submitGatherOutButton",
        };
      case "AUTO_APPROVAL":
        return {
          ...commonConfig,
          title: "Go To Checkout",
          type: "submit",
          testId: "createRequisitionButton",
        };
      case "ORDER":
        return {
          ...commonConfig,
          title: "Go To Checkout",
          type: "submit",
          testId: "goToCheckoutButton",
        };
      case "REQUISITION":
        return {
          ...commonConfig,
          title: "Create Requisition",
          type: "submit",
          testId: "createRequisitionButton",
        };
    }
  }, [
    isPunchoutSession,
    isGatherOutSession,
    configuration,
    hasLineItems,
    isPunchoutOrderPending,
    isGatherOutSubmissionPending,
  ]);

  const actionBarHelperButtonsSettings = useMemo(
    () => [
      ...((!draft || draft.status === "CREATION_STARTED") &&
      !isPunchoutSession &&
      !isGatherOutSession
        ? [
            {
              title: "Save As Draft",
              onClick: handleCreatingDraft,
              testId: "saveDraftButton",
              isLoading: isCreatingDraft || isUpdatingDraft,
            },
          ]
        : []),
      ...(!isBasketEmpty && isOfflineDraftEnabled && !isPunchoutSession && !isGatherOutSession
        ? [
            {
              title: "Share As Email",
              onClick: handleShareBasket,
              isLoading: isSharingBasket || isSharingBasketAfterDraftCreation,
              testId: "shareBasketButton",
            },
          ]
        : []),
      ...(isGatherOutSession
        ? [
            {
              title: cancelGatherOutLabel,
              onClick: cancelGatherOut,
              isDisabled: isGatherOutSubmissionPending,
              testId: "cancelGatherOutButton",
            },
          ]
        : []),
    ],
    [
      draft,
      isPunchoutSession,
      isGatherOutSession,
      handleCreatingDraft,
      isCreatingDraft,
      isUpdatingDraft,
      isBasketEmpty,
      isOfflineDraftEnabled,
      handleShareBasket,
      isSharingBasket,
      isSharingBasketAfterDraftCreation,
      cancelGatherOutLabel,
      cancelGatherOut,
      isGatherOutSubmissionPending,
    ]
  );

  return (
    <>
      <BasketHeader />
      <AllowOnlineOnlyWarningModal />
      <CancelGatherOutConfirmationModal onConfirmed={handleCancelGatherOutConfirmed} />
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(handleValidBasketSubmit, handleInvalidBasketSubmit)}
          noValidate
        >
          <div className="w-full min-h-screen bg-neutral_100">
            {!isBasketEmpty && (
              <ActionBar
                primaryButtonSettings={actionBarPrimaryButtonSettings}
                helperButtons={actionBarHelperButtonsSettings}
                backButtonSettings={{
                  title: "Continue Shopping",
                  onClick: () => {
                    navigate(RoutesConfig.gatherSetup);
                  },
                }}
              />
            )}
            {isBasketEmpty && isGatherOutSession && (
              <ActionBar
                helperButtons={actionBarHelperButtonsSettings}
                backButtonSettings={{
                  title: "Continue Shopping",
                  onClick: () => {
                    navigate(RoutesConfig.gatherSetup);
                  },
                }}
              />
            )}
            <div className={`pt-5 ${containerPadding}`}>
              {!isBasketEmpty && <hr />}
              <div className="py-5 flex justify-between items-center">
                <Heading size="100">Basket</Heading>
                {!isBasketEmpty && draft && draft.status !== "CREATION_STARTED" && (
                  <OrderDraftSavedInfo
                    draftLastUpdated={draft.updatedAt}
                    isSavingDraft={isCreatingDraft || isUpdatingDraft}
                    basketLastUpdated={basketLastUpdated}
                  />
                )}
                <div>
                  {!isBasketEmpty && (
                    <div className="ml-auto">
                      <div className="items-center flex flex-row">
                        <Paragraph size="100">Total:</Paragraph>
                        <div className="pl-2">
                          <Heading size="200">
                            {hasLineItems ? formatMoney(grandTotal) : "TBD"}
                          </Heading>
                        </div>
                      </div>
                      <div className="flex flex-col text-right">
                        <Paragraph size="100">
                          {t("pages.basket.nrItems", {
                            count: numberOfItems,
                            formattedCount: formatNumber(numberOfItems),
                          })}
                          {areOrderExtraItemsEnabled &&
                            ` + ${t("pages.basket.nrExtraItems", {
                              count: numberOfExtraItems,
                              formattedCount: formatNumber(numberOfExtraItems),
                            })}`}
                        </Paragraph>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {configuration?.basketAnswersForm && (
                <BasketAnswersFormModalController
                  isBasketAnswersFormModalOpen={isBasketAnswersFormModalOpen}
                  isFromDraft={isFromDraft}
                  basketAnswersForm={configuration.basketAnswersForm}
                  onCloseModal={closeBasketAnswersFormModal}
                />
              )}
              <hr />
              <div className="flex justify-center">
                <div data-testid="basket-container" className="w-full pb-6.5 mt-6">
                  {hasLineOrRfqItems ? (
                    <>
                      {hasRfqFeature && (
                        <Label size="300" className="text-text-whiteDisabled block mb-0">
                          CATALOG ITEMS
                        </Label>
                      )}
                      {lineItems.map((lineItem, index) => (
                        <LineItemFastGather
                          lineItem={lineItem}
                          index={index}
                          key={lineItem.sku.id}
                        />
                      ))}
                      {hasRfqFeature && (
                        <>
                          <Label size="300" className="text-text-whiteDisabled block mt-5 mb-0">
                            REQUESTED ITEMS
                          </Label>
                          {rfqItems.map((rfqItem) => {
                            return <RfqItem rfqItem={rfqItem} key={rfqItem.id} />;
                          })}
                          <RequestProductInline basket />
                        </>
                      )}
                    </>
                  ) : (
                    <BasketEmpty />
                  )}
                  {areOrderExtraItemsEnabled && (
                    <BasketExtraItemsSection
                      className={classnames({ "mt-6": hasLineOrRfqItems })}
                      extraItems={formExtraItems}
                      prependExtraItem={prependFormExtraItem}
                      removeExtraItem={removeFormExtraItem}
                      unitOfMeasures={unitOfMeasures}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
