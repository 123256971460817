import { useEffect } from "react";

import { OpenAPI as AttachmentsOpenAPI } from "@web/attachments";
import {
  APP_VERSION_HTTP_HEADER_NAME,
  UNLEASH_ATTRIBUTES_HTTP_HEADER_NAME,
  useFeatureFlagsManagement,
} from "@web/common";

import { OFFLINE_HTTP_HEADERS } from "src/config/constants";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useNetworkDetector } from "src/contexts/NetworkDetector";

import { OpenAPI } from "../typegens";
import { useOfflineCapabilities } from "./OfflineCapabilities";

const VITE_GIT_HASH = (import.meta.env.VITE_GIT_HASH as string) || "";

export const ApiHeadersSetup = () => {
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { currentNetworkState } = useNetworkDetector();
  const [{ configuration }] = useAppStateContext();
  const { unleashContextFlagProperties } = useFeatureFlagsManagement();

  useEffect(() => {
    const customOfflineHeaders = {
      [OFFLINE_HTTP_HEADERS.OFFLINE_CAPABILITIES]: `${areOfflineCapabilitiesEnabled}`,
      [OFFLINE_HTTP_HEADERS.NETWORK_STATE]: `${currentNetworkState}`,
      [OFFLINE_HTTP_HEADERS.VESSEL_ID]: `${configuration?.vessel.id}`,
    };
    const unleashOverrideAttributesHeader = {
      [UNLEASH_ATTRIBUTES_HTTP_HEADER_NAME]: JSON.stringify(unleashContextFlagProperties),
    };
    const appVersionHeader = {
      [APP_VERSION_HTTP_HEADER_NAME]: VITE_GIT_HASH,
    };
    OpenAPI.HEADERS = {
      ...customOfflineHeaders,
      ...unleashOverrideAttributesHeader,
      ...appVersionHeader,
    };
    AttachmentsOpenAPI.HEADERS = unleashOverrideAttributesHeader;
  }, [
    areOfflineCapabilitiesEnabled,
    configuration?.vessel.id,
    currentNetworkState,
    unleashContextFlagProperties,
  ]);

  return null;
};
