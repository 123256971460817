import { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";

import { Loading } from "@web/ui";

import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useAppCleanup } from "src/hooks/useAppCleanup";
import { useToastMessage } from "src/hooks/useToastMessage";

export const GatherOutExitPoint = () => {
  const [{ gatherOutConfig }] = useAppStateContext();
  const { setToastMessage } = useToastMessage();
  const navigate = useNavigate();
  const isSubmitExit = !!useMatch(RoutesConfig.submitGatherOutExitPoint);
  const isCancelExit = !!useMatch(RoutesConfig.cancelGatherOutExitPoint);
  const [returnUrl, setReturnUrl] = useState("");

  const { cleanupVesselToken, cleanupVesselSession, cleanupPunchoutSession, cleanupOfflineState } =
    useAppCleanup();

  useEffect(() => {
    if (returnUrl) {
      return;
    }

    if (!gatherOutConfig) {
      setToastMessage({
        type: "failure",
        message:
          "There was an error while finishing the Gather Out session. Please try again and if that does not work, please contact our Customer Support team.",
      });
      navigate(-1);
      return;
    }

    if (isSubmitExit) {
      setReturnUrl(gatherOutConfig.submitReturnToUrl);
    } else if (isCancelExit) {
      setReturnUrl(gatherOutConfig.cancelReturnToUrl);
    }

    cleanupOfflineState();
    cleanupPunchoutSession();
    cleanupVesselSession();
    cleanupVesselToken();
  }, [
    cleanupOfflineState,
    cleanupPunchoutSession,
    cleanupVesselSession,
    cleanupVesselToken,
    gatherOutConfig,
    isCancelExit,
    isSubmitExit,
    navigate,
    returnUrl,
    setToastMessage,
  ]);

  useEffect(() => {
    if (!returnUrl) {
      return;
    }
    window.location.href = returnUrl;
  }, [returnUrl]);

  return <Loading />;
};
