import _isEqual from "lodash/isEqual";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Loading } from "@web/ui";

import {
  PUNCHOUT_SESSION_ID_STORAGE_KEY,
  SUPPLIER_ID_STORAGE_KEY,
  VESSEL_TOKEN_STORAGE_KEY,
} from "src/config/constants";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { GatherOutExitPoint, LocalGatherOutService } from "src/domain";
import { useAppCleanup } from "src/hooks/useAppCleanup";
import { NoAccess } from "src/pages/NoAccess";
import { RetrieveVesselToken } from "src/pages/RetrieveVesselToken";
import { OpenAPI } from "src/typegens";

export const Authenticated = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const [, dispatch] = useAppStateContext();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const firstUpdate = useRef(true);

  const { cleanupVesselSession, cleanupPunchoutSession, cleanupOfflineState } = useAppCleanup();

  useEffect(() => {
    if (firstUpdate.current === false) {
      return;
    }
    firstUpdate.current = false;

    const params = new URLSearchParams(location.search);

    const newToken = params.get("vesselToken") || "";
    const newPunchoutSessionId = params.get("punchoutSessionId") || "";
    const newSupplierId = params.get("supplierId") || "";
    const newGatherOutConfig = LocalGatherOutService.getGatherOutConfigFromUrl(location.search);

    const storedToken = localStorage.getItem(VESSEL_TOKEN_STORAGE_KEY);
    const storedPunchoutSessionId = localStorage.getItem(PUNCHOUT_SESSION_ID_STORAGE_KEY);
    const storedSupplierId = localStorage.getItem(SUPPLIER_ID_STORAGE_KEY);
    const storedGatherOutConfig = LocalGatherOutService.getStoredGatherOutConfig();

    const token = newToken || storedToken;
    const punchoutSessionId = newPunchoutSessionId || storedPunchoutSessionId;
    const supplierId = newSupplierId || storedSupplierId;
    const gatherOutConfig = newGatherOutConfig || storedGatherOutConfig;

    const isNewToken = !!(newToken && newToken !== storedToken);

    const isPunchoutSession = !!(punchoutSessionId && supplierId);
    const isNewPunchoutSession =
      !!(newPunchoutSessionId && newPunchoutSessionId !== storedPunchoutSessionId) ||
      !!(newSupplierId && newSupplierId !== storedSupplierId);

    const isGatherOutSession = !!gatherOutConfig;
    const isNewGatherOutSession =
      !!newGatherOutConfig && !_isEqual(newGatherOutConfig, storedGatherOutConfig);

    const doesNeedCleanup =
      (isNewToken && !!storedToken) ||
      (isNewPunchoutSession && !!storedPunchoutSessionId) ||
      (isNewGatherOutSession && !!storedGatherOutConfig);

    const hasUrlParams = !!(newToken || newPunchoutSessionId || newGatherOutConfig);

    if (isNewToken) {
      localStorage.setItem(VESSEL_TOKEN_STORAGE_KEY, newToken);
    }

    if (doesNeedCleanup) {
      cleanupVesselSession();
      cleanupPunchoutSession();
      cleanupOfflineState();
      window.location.reload();
      return;
    }

    if (token) {
      OpenAPI.TOKEN = token;
      dispatch({ type: "setVesselToken", value: token });
      setIsAuthorized(true);
    }

    if (isPunchoutSession) {
      dispatch({ type: "setIsPunchoutSession", value: true });

      if (isNewPunchoutSession) {
        if (newPunchoutSessionId) {
          localStorage.setItem(PUNCHOUT_SESSION_ID_STORAGE_KEY, newPunchoutSessionId);
        }
        if (newSupplierId) {
          localStorage.setItem(SUPPLIER_ID_STORAGE_KEY, newSupplierId);
        }
      }
    }

    if (isGatherOutSession) {
      dispatch({ type: "setGatherOutConfig", value: gatherOutConfig });

      if (isNewGatherOutSession) {
        LocalGatherOutService.setStoredGatherOutConfig(newGatherOutConfig);
      }
    }

    if (hasUrlParams) {
      // This strips search params (which we want to do only in case of new token)
      navigate(location.pathname, { replace: true });
    }

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (location.pathname === RoutesConfig.retrieveVesselToken) {
    return <RetrieveVesselToken />;
  }

  if (
    location.pathname === RoutesConfig.submitGatherOutExitPoint ||
    location.pathname === RoutesConfig.cancelGatherOutExitPoint
  ) {
    return <GatherOutExitPoint />;
  }

  if (!isAuthorized) {
    return <NoAccess />;
  }
  return children;
};
