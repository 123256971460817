/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { LiteGatheroutBasket } from "../models/LiteGatheroutBasket";

export class LiteGatheroutControllerService {
  /**
   * @returns LiteGatheroutBasket OK
   * @throws ApiError
   */
  public static getGatheroutBasket(
    {
      id,
    }: {
      id: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<LiteGatheroutBasket> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/lite/gatherout/basket/{id}",
      path: {
        id: id,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
