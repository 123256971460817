import { useEffect, useRef } from "react";
import { ZodType } from "zod";

type Params<StoredData> = {
  localStorageKey: string;
  storeReadCallback?: (data: StoredData) => void;
  stateToStore: StoredData | undefined;
  storeSchema: ZodType;
};

export const usePersistedState = <StoredData>({
  localStorageKey,
  storeReadCallback,
  stateToStore,
  storeSchema,
}: Params<StoredData>) => {
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current === false) {
      return;
    }
    try {
      const data = localStorage.getItem(localStorageKey);
      const value = JSON.parse(data as string);
      if (value) {
        const parsingResult = storeSchema.parse(value) as StoredData;
        if (storeReadCallback) {
          storeReadCallback(parsingResult);
        }
      }
    } catch (error) {
      console.log(error);
      localStorage.removeItem(localStorageKey);
    } finally {
      firstUpdate.current = false;
    }
  }, [storeReadCallback, localStorageKey, storeSchema]);

  useEffect(() => {
    if (firstUpdate.current === true || !stateToStore) {
      return;
    }
    try {
      const parsingResult = storeSchema.parse(stateToStore) as StoredData;
      localStorage.setItem(localStorageKey, JSON.stringify(parsingResult));
    } catch (error) {
      console.log(error);
    }
  }, [localStorageKey, stateToStore, storeSchema]);
};
