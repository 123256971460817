import {
  GatherOutConfig,
  GatherOutConfigSchema,
  GatherOutFlow,
  decodeGatherOutUrl,
  isRegatherOutFlow,
} from "@web/common";
import { ParsingError } from "@web/models";

import { VESSEL_STORAGE_KEY } from "src/config/constants";
import { RoutesConfig } from "src/config/routes";
import { AppStateStorage } from "src/state/models";

import {
  CancelGatherOutModalLabels,
  GatherOutSubmissionData,
  GatherOutSubmissionDataSchema,
  LiteGatherOutBasketSchema,
  LocalLiteGatherOutBasket,
} from "../models";

const gatherOutFlowToEntryPathMap = new Map<GatherOutFlow, string>([
  ["EDIT_REQUISITION", RoutesConfig.basket],
]);

export const LocalGatherOutService = {
  /**
   * Converters/mappers
   */
  convertFromApiToValidatedGatherOutBasket: (
    apiGatherOutBasket: LocalLiteGatherOutBasket
  ): LocalLiteGatherOutBasket => {
    // Do not shorten this statement to ensure typechecking compile-time
    const parseInput: LocalLiteGatherOutBasket = apiGatherOutBasket;
    const parseResult = LiteGatherOutBasketSchema.safeParse(parseInput);

    if (!parseResult.success) {
      throw new ParsingError("This Gather Out basket is missing attributes", parseResult.error);
    }

    return parseResult.data;
  },
  convertFromAppStateToValidatedGatherOutConfig: (
    appStateGatherOutConfig: GatherOutConfig | undefined
  ): GatherOutConfig | undefined => {
    // Do not shorten this statement to ensure typechecking compile-time
    const parseInput: GatherOutConfig | undefined = appStateGatherOutConfig;
    const parseResult = GatherOutConfigSchema.safeParse(parseInput);
    return parseResult.success ? parseResult.data : undefined;
  },
  convertGatherOutSubmissionDataToValidatedGatherOutSubmissionData: (
    gatherOutSubmissionData: GatherOutSubmissionData
  ): GatherOutSubmissionData => {
    // Do not shorten this statement to ensure typechecking compile-time
    const parseInput: GatherOutSubmissionData = gatherOutSubmissionData;
    const parseResult = GatherOutSubmissionDataSchema.safeParse(parseInput);

    if (!parseResult.success) {
      throw new ParsingError("This Gather Out data is missing attributes", parseResult.error);
    }

    return parseResult.data;
  },

  /**
   * Calculators
   */
  getGatherOutEntryPath: (gatherOutConfig: GatherOutConfig | undefined): string | undefined =>
    gatherOutConfig?.gatherOutFlow &&
    gatherOutFlowToEntryPathMap.get(gatherOutConfig.gatherOutFlow),
  getGatherOutCancelLabel: (gatherOutConfig: GatherOutConfig | undefined): string => {
    if (isRegatherOutFlow(gatherOutConfig?.gatherOutFlow)) {
      return "Cancel Basket Changes";
    }
    return "Cancel Without Saving";
  },
  getGatherOutCancelModalLabels: (
    gatherOutConfig: GatherOutConfig | undefined
  ): CancelGatherOutModalLabels => {
    if (isRegatherOutFlow(gatherOutConfig?.gatherOutFlow)) {
      return {
        title: "Are you sure you want to cancel basket changes?",
        subtitle: "Any changes you made to the basket will be lost.",
        confirm: "Yes, Cancel Basket Changes",
        abort: "No, Don't Cancel",
      };
    }
    return {
      title: "Are you sure you want to cancel this basket?",
      subtitle: "Any changes you made to the basket will be lost.",
      confirm: "Yes, Cancel Basket",
      abort: "No, Don't Cancel",
    };
  },

  /**
   * State checkers
   */
  getIsRegatherOutFlow: isRegatherOutFlow,

  /**
   * Other utils
   */
  getGatherOutConfigFromUrl: decodeGatherOutUrl,

  // Extract Gather Out config stored with the rest of the app's state
  getStoredGatherOutConfig: (): GatherOutConfig | undefined => {
    try {
      const rawStoredData = localStorage.getItem(VESSEL_STORAGE_KEY);
      const storedAppState = { ...JSON.parse(rawStoredData as string) } as AppStateStorage;
      if (storedAppState.gatherOutConfig) {
        return GatherOutConfigSchema.parse(storedAppState.gatherOutConfig);
      }
      return undefined;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  },

  // Overwrite gatherOutConfig in the stored app's state
  setStoredGatherOutConfig: (gatherOutConfig: GatherOutConfig): void => {
    try {
      const validatedGatherOutConfig =
        LocalGatherOutService.convertFromAppStateToValidatedGatherOutConfig(gatherOutConfig);
      if (!validatedGatherOutConfig) {
        console.log("GatherOut config to store is invalid");
        return;
      }

      const rawStoredData = localStorage.getItem(VESSEL_STORAGE_KEY);
      const storedAppState = { ...JSON.parse(rawStoredData as string) } as AppStateStorage;
      const stateToStore: AppStateStorage = {
        ...storedAppState,
        gatherOutConfig: validatedGatherOutConfig,
      };
      localStorage.setItem(VESSEL_STORAGE_KEY, JSON.stringify(stateToStore));
    } catch (error) {
      console.log(error);
    }
  },
};
