import { z } from "zod";

import { assertTypeEquality } from "@web/utils";

import { LiteUpdateRequisitionRequest } from "src/typegens";

import { GatherOutSubmissionCatalogItemSchema } from "./GatherOutSubmissionCatalogItemSchema";
import { GatherOutSubmissionExtraItemSchema } from "./GatherOutSubmissionExtraItemSchema";

export const GatherOutSubmissionDataSchema = z.object({
  catalogItems: z.array(GatherOutSubmissionCatalogItemSchema),
  extraItems: z.array(GatherOutSubmissionExtraItemSchema),
});

export type GatherOutSubmissionData = z.infer<typeof GatherOutSubmissionDataSchema>;

assertTypeEquality<
  GatherOutSubmissionData,
  LiteUpdateRequisitionRequest,
  GatherOutSubmissionData
>();
